.image-container {
    position: relative;
    display: inline-block;
}

.thumbnail {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.large-image {
    display: none;
    position: absolute;
    top: 0;
    left: 160px;
    border: 2px solid #000;
}

.image-container:hover .large-image {
    top: 0;
    left: -360px;
    display: block;
}

.large-image img {
    width: 400px;
    height: auto;
}